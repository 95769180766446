<template>
    <div class="d-flex flex-wrap mt-4">
        <table class="table" :class="tableDark">
            <thead>
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Slug</th>
                    <th v-if="showRole" scope="col">Rol otorgante</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td class="col-3">
                        <span>
                            {{ item.slug }}
                        </span>
                    </td>
                    <td class="col-5">
                        <span>
                            {{ item.name }}
                        </span>
                    </td>
                    <td class="col-4">
                        <span>
                            {{ item.role }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "PermissionsTable",
    props: {
        items: {
            type: Array,
            default: () => [
                {
                    name: "Get Own User",
                    slug: "get:UserOwn",
                    role: "Super"
                }
            ],
            required: true
        },
        showRole: {
            type: Boolean,
            default: () => false,
            required: true
        }
    },
    computed: {
        ...mapGetters("control", ["tableDark"])
    }
};
</script>

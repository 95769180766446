<template>
    <div>
        <h3 class="mx-3 mb-3">
            Detalle de rol
            <span class="text-primary">
                {{ role.slug }}
            </span>
        </h3>
        <div id="general" class="d-flex flex-wrap">
            <div class="col-12 col-md-6 mt-5">
                <text-component label="Nombre" :value="role.name" />
            </div>
            <div class="col-12 col-md-6 mt-5">
                <text-component label="Slug" :value="role.slug" />
            </div>

            <text-component
                class="col-12 mt-5"
                label="Descripción"
                :value="role.description"
            />
        </div>
        <div v-if="role.permissions.length" class="d-flex flex-wrap">
            <h4 class="mt-4 ml-4">Permisos de rol "{{ role.name }}"</h4>
            <div class="col-12 d-flex justify-content-center">
                <permissions-table
                    :items="role.permissions"
                    :show-role="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PermissionsTable from "@/components/permissions/PermissionsTable.vue";
import TextComponent from "@/components/utils/TextComponent.vue";

export default {
    name: "RoleView",
    components: {
        PermissionsTable,
        TextComponent
    },
    props: {
        role: {
            type: Object,
            default: () => ({
                id: 2,
                slug: "",
                name: "",
                description: "",
                permissions: [
                    {
                        id: 0,
                        name: "",
                        slug: ""
                    }
                ]
            }),
            required: true
        }
    }
};
</script>

<template>
    <div class="my-4">
        <div class="w-100">
            <h1 class="h3 text-center">
                Roles en total
                <strong class="text-primary">
                    {{ itemsCurrent.length }}
                </strong>
            </h1>
        </div>

        <div class="d-flex flex-wrap mt-2">
            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12"
            >
                <div
                    class="my-3 d-flex align-items-center justify-content-around"
                >
                    <vs-button
                        v-if="$ability.hasScope('role:Sync')"
                        floating
                        circle
                        icon
                        @click="syncRolesComponent"
                    >
                        ♻
                    </vs-button>
                </div>

                <div
                    class="my-3 d-flex align-items-center justify-content-around"
                >
                    <vs-button circle icon floating @click="reloadRoles">
                        <reload-icon />
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-show="showTable" class="w-100 mt-2">
            <b-table
                :fields="$data.$fields"
                :per-page="itemsCurrent.length"
                :items="itemsCurrent"
                :dark="activeDark"
                responsive
                outlined
                no-border-collapse
                hover
                class="col-12"
            >
                <template #cell(slug)="data">
                    <div class="d-flex align-items-center">
                        <router-link
                            :to="`/app/roles/detail/${data.item.slug}`"
                            class="text-center font-weight-normal btn btn-primary p-1"
                        >
                            <small>
                                {{ data.item.slug }}
                            </small>
                        </router-link>
                    </div>
                </template>
                <template #cell(name)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.name }}
                    </small>
                </template>
            </b-table>
        </div>

        <vs-dialog
            v-model="isOpenModalSync"
            scroll
            overflow-hidden
            auto-width
            :loading="isLoading"
        >
            <template #header>
                <h1>Roles y permisos creados # {{ itemsSync.length }}</h1>
            </template>
            <div class="con-content">
                <role-view
                    v-for="(item, index) in itemsSync"
                    :key="index"
                    :role="item"
                />
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { validateConfirmationMessage } from "@/common/lib/transformers";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import RoleView from "@/components/roles/RoleView.vue";

export default {
    name: "IndexRoles",
    components: {
        RoleView,
        ReloadIcon
    },
    layout: "app",
    data: () => ({
        isOpenModalSync: false,
        loaderInstance: null,
        isLoading: false,
        itemsCurrent: [],
        showTable: false,
        itemsSync: [],
        $fields: [
            { key: "slug", label: "Slug", sortable: true },
            { key: "name", label: "Nombre", sortable: true }
        ]
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("role:List", "/app"))) return;
        this.listAllRolesComponent();
    },
    methods: {
        ...mapActions("roles", ["listAllRoles", "syncRoles"]),
        async listAllRolesComponent(required = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const items = await this.listAllRoles({ required });
                this.isLoading = false;
                this.itemsCurrent = items;
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadRoles() {
            await this.listAllRolesComponent(true);
        },
        async syncRolesComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: `Estas a punto de reiniciar el sistema de roles y permisos. `,
                text: `Esta acción es unicamente permitida para el super usuario y solo debe de hacerse cuando se crean nuevas acciones ,
                eres consiente de lo que estas haciendo ?. Escribe 'Si' para reiniciar `,
                icon: "info",
                input: "text",
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#253C99",
                showCancelButton: true
            });
            if (
                dialogResult.isConfirmed &&
                validateConfirmationMessage(dialogResult.value)
            ) {
                try {
                    this.itemsSync = await this.syncRoles();
                    this.isOpenModalSync = true;
                    const sound = this.$sounds.get("success");
                    sound.volume(0.9);
                    sound.play();
                } catch (error) {
                    const sound = this.$sounds.get("error");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                }
            }
        }
    }
};
</script>

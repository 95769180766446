<template>
    <div class="d-flex flex-column h-100 w-100 mw-100">
        <div class="position-relative">
            <label class="left-13px"> {{ label }} </label>
        </div>
        <div class="d-flex px-2 align-items-center h-100">
            <span class="ml-2">{{ value }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextComponent",
    props: {
        label: {
            type: String,
            default: () => "Label"
        },
        value: {
            type: String,
            default: () => "Value"
        }
    }
};
</script>
